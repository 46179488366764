import React, { useEffect, useState } from "react";
import rd3 from 'react-d3-library';
import { ChartUtils } from "../../utilities/chart-utils";

const RD3Component = rd3.Component;

const COMPONENT_CLASS = "c-donut-chart";

export interface DonutChartProps {
    percentage?: number;
    mainText?: string;
    showPercentSymbol?: boolean;
    showTotal?: boolean;
    thickness?: number;
    title?: string;
    badgeText?: string;
    subText?: string;
    mainTextFontSize?: string;
    fillColor?: string;
}

const DonutChart: React.FC<DonutChartProps> = (props) => {
    const [data, setData] = useState<HTMLDivElement>();

    useEffect(() => {
        setData(ChartUtils.drawDonutChart(props.percentage ?? 0, props.thickness || 20, props.fillColor));
    }, [props.fillColor, props.percentage, props.thickness, setData]);

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <RD3Component
                data={data} />
            <div className={`${COMPONENT_CLASS}__inner`}>
                <div className={`${COMPONENT_CLASS}__inner__score`} style={{ fontSize: props.mainTextFontSize }}>
                    {props.mainText ? props.mainText :
                        props.percentage == null ? "0" : props.percentage + (props.showPercentSymbol ? "%" : "")}
                </div>
                { // if
                    props.title != null &&
                    <div className={`${COMPONENT_CLASS}__inner__title`} dangerouslySetInnerHTML={{ __html: props.title }}></div>
                }
                {props.badgeText &&
                    <div className={`${COMPONENT_CLASS}__inner__badge`}>
                        {props.badgeText}
                    </div>
                }
                {props.subText &&
                    <div className={`${COMPONENT_CLASS}__inner__sub-text`}>
                        {props.subText}
                    </div>
                }
            </div>
        </div>
    );
};

export default DonutChart;