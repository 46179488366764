import { ErrorLog } from "../../models/interfaces/error-log";
import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "errorLogs";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new errorLog to the errorLogs collection
 * @param {ErrorLog} errorLog - The errorLog that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<ErrorLog>} A promise to the newly added errorLog
 */
const add = async (errorLog: ErrorLog, currentUser: User | null = null) => {
    return FirestoreService.add<ErrorLog>(COLLECTION_NAME, {...errorLog, version: "Portal"}, currentUser);
};

/**
 * Delete a errorLog collection by the Id
 * @param {string} id - The Id of the errorLog being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific errorLog by the id
 * @param {string} id - The Id of the errorLog that we are retrieving
 * @returns {Promise<ErrorLog>} A promise for the Tag we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<ErrorLog>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<ErrorLog>(COLLECTION_NAME, conditions, order, limit);
};


/**
 * Get all of the errorLogs stored in the database
 * @returns {Promise<Tag[]>} A promise for the collection of errorLogs
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<ErrorLog>(COLLECTION_NAME, order);
};

/**
 * Save the specified errorLog in the database
 * @param {ErrorLog} errorLog - The errorLog that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<ErrorLog>} A promise for the errorLog that is being updated
 */
const save = async (errorLog: ErrorLog, currentUser: User | null = null) => {
    return FirestoreService.save<ErrorLog>(COLLECTION_NAME, errorLog, currentUser);
};

/**
 * Update the specified errorLog stored in the database
 * @param {ErrorLog} errorLog - The errorLog that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<ErrorLog>} A promise for the errorLog that is being updated
 */
const update = async (errorLog: ErrorLog, currentUser: User | null = null) => {
    return FirestoreService.update<ErrorLog>(COLLECTION_NAME, errorLog, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const ErrorLogService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    save,
    update,
};

export default ErrorLogService;

// #endregion Exports