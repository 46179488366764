import React from "react";
import NonLinearGraphUtil from "../../utilities/non-linear-graph-util";

const COMPONENT_CLASS = "c-risk-chart-vertical";

export interface RiskChartVerticalProps {
    heightPercentage?: number | null; // Provided height of the bar. If null\undefined then use score to identify the height
    score?: number | null;
    breakpoints: {
        min: number,
        max: number,
        label: string,
        bgColor?: string,
        subLabel?: string,
    }[];
    decimals: boolean;
}

const RiskChartVertical: React.FC<RiskChartVerticalProps> = (props) => {
    const {
        heightPercentage,
        score,
        breakpoints,
        decimals,
    } = props;

    let segmentHeight = "25%";

    switch (breakpoints.length) {
        case 2:
            segmentHeight = "50%";
            break;
        case 3:
            segmentHeight = "33.333333%"
            break;
        case 4:
            segmentHeight = "25%";
            break;
    }

    let barHeightPercentage;

    if (heightPercentage !== null && heightPercentage !== undefined) {
        barHeightPercentage = heightPercentage;
    }
    else if (score !== null && score !== undefined) {
        switch (breakpoints.length) {
            case 2:
                barHeightPercentage = NonLinearGraphUtil.twoSectionDownScale(
                    score,
                    {
                        sections: 2,
                        rangeOne: { start: breakpoints[1].min, end: breakpoints[1].max },
                        rangeTwo: { start: breakpoints[0].min, end: breakpoints[0].max },
                    }
                ) * 100;
                break;
            case 3:
                barHeightPercentage = NonLinearGraphUtil.threeSectionDownScale(
                    score,
                    {
                        sections: 3,
                        rangeOne: { start: breakpoints[2].min, end: breakpoints[2].max },
                        rangeTwo: { start: breakpoints[1].min, end: breakpoints[1].max },
                        rangeThree: { start: breakpoints[0].min, end: breakpoints[0].max },
                        decimals: decimals,
                    }
                ) * 100;
                break;
            case 4:
                barHeightPercentage = NonLinearGraphUtil.fourSectionDownScale(
                    score,
                    {
                        sections: 4,
                        rangeOne: { start: breakpoints[3].min, end: breakpoints[3].max },
                        rangeTwo: { start: breakpoints[2].min, end: breakpoints[2].max },
                        rangeThree: { start: breakpoints[1].min, end: breakpoints[1].max },
                        rangeFour: { start: breakpoints[0].min, end: breakpoints[0].max },
                        decimals: decimals,
                    }
                ) * 100;
                break;
        }
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className="flex gap-4 w-full h-full justify-center">
                <div className="w-1/4 h-full relative">
                    {barHeightPercentage !== undefined &&
                        <div className={`${COMPONENT_CLASS}__bar`} style={{ height: `${barHeightPercentage}%` }}></div>
                    }
                    <div className="w-full h-full">
                        {breakpoints[0] &&
                            <div className={`${COMPONENT_CLASS}__segment bg-green-medium`} style={{
                                backgroundColor: `${breakpoints[0].bgColor}`,
                                height: segmentHeight,
                            }}>
                            </div>
                        }
                        {breakpoints[1] &&
                            <div className={`${COMPONENT_CLASS}__segment bg-blue-medium`} style={{
                                backgroundColor: `${breakpoints[1].bgColor}`,
                                height: segmentHeight,
                            }}>
                            </div>
                        }
                        {breakpoints[2] &&
                            <div className={`${COMPONENT_CLASS}__segment bg-yellow-dark`} style={{
                                backgroundColor: `${breakpoints[2].bgColor}`,
                                height: segmentHeight,
                            }}>
                            </div>
                        }
                        {breakpoints[3] &&
                            <div className={`${COMPONENT_CLASS}__segment bg-red-medium`} style={{
                                backgroundColor: `${breakpoints[3].bgColor}`,
                                height: segmentHeight,
                            }}>
                            </div>
                        }
                    </div>
                </div>

                <div className="h-full">
                    {breakpoints[0] &&
                        <div className={`${COMPONENT_CLASS}__segment`} style={{ height: segmentHeight }}>
                            <div className={`${COMPONENT_CLASS}__${((score !== null && score !== undefined && score >= breakpoints[0].min && score <= breakpoints[0].max) || barHeightPercentage === 100) ? "highlighted" : ""} flex flex-col`}>
                                <div className="risk-chart-label">{breakpoints[0].label}</div>
                                {breakpoints[0].subLabel &&
                                    <div className="risk-chart-sub-label">{breakpoints[0].subLabel}</div>
                                }
                            </div>
                        </div>
                    }
                    {breakpoints[1] &&
                        <div className={`${COMPONENT_CLASS}__segment`} style={{ height: segmentHeight }}>
                            <div className={`${COMPONENT_CLASS}__${(score !== null && score !== undefined && score >= breakpoints[1].min && score <= breakpoints[1].max) ? "highlighted" : ""} flex flex-col`}>
                                <div className="risk-chart-label">{breakpoints[1].label}</div>
                                {breakpoints[1].subLabel &&
                                    <div className="risk-chart-sub-label">{breakpoints[1].subLabel}</div>
                                }
                            </div>
                        </div>
                    }
                    {breakpoints[2] &&
                        <div className={`${COMPONENT_CLASS}__segment`} style={{ height: segmentHeight }}>
                            <div className={`${COMPONENT_CLASS}__${(score !== null && score !== undefined && score >= breakpoints[2].min && score <= breakpoints[2].max) ? "highlighted" : ""} flex flex-col`}>
                                <div className="risk-chart-label">{breakpoints[2].label}</div>
                                {breakpoints[2].subLabel &&
                                    <div className="risk-chart-sub-label">{breakpoints[2].subLabel}</div>
                                }
                            </div>
                        </div>
                    }
                    {breakpoints[3] &&
                        <div className={`${COMPONENT_CLASS}__segment`} style={{ height: segmentHeight }}>
                            <div className={`${COMPONENT_CLASS}__${(score !== null && score !== undefined && score >= breakpoints[3].min && score <= breakpoints[3].max) ? "highlighted" : ""} flex flex-col`}>
                                <div className="risk-chart-label">{breakpoints[3].label}</div>
                                {breakpoints[3].subLabel &&
                                    <div className="risk-chart-sub-label">{breakpoints[3].subLabel}</div>
                                }
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
};

export default RiskChartVertical;
