import { ActivityType } from "../models/enumerations/activity-type";
import { GroupIds } from "../models/enumerations/group-ids";
import { VideoSize } from "../models/enumerations/video-size";
import { CorrectiveExercise } from "../models/interfaces/corrective-exercises/corrective-exercise";
import { CorrectiveRoutineInput } from "../models/interfaces/corrective-exercises/corrective-routine-input";
import { UserCorrectiveExercise } from "../models/interfaces/corrective-exercises/user-corrective-exercise";
import { MovementScore } from "../models/interfaces/scores/movement-score";
import { IUser } from "../models/interfaces/assessment-user";
import DateUtil from "./date-util";
import { FirebaseUtils } from "./firebase-utils";
import MskScoreUtil from "./msk-score-util";
import CorrectiveExercisesService from "./services/corrective-exercises-service/corrective-exercises-serivce";
import UserCorrectiveExercisesService from "./services/corrective-exercises-service/user-corrective-exercises-service";
// import TodayService from "./services/today-service/today-service";
import WellnessProgramService from "./services/wellness-program-service";

const VIDEO_SIZE = VideoSize.PORTRAIT_854P;
const EXTRA_ID = "EXTRA";

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Marks the specified corrective exercise as complete
 * @param   {string} userId - User who is completing an exercise day
 * @param   {number} day - 1-based day to mark as complete
 * @returns {Promise<void>}
 */
const finishDay = async (
    userId: string,
    day: number,
    currentIdentity?: IUser
): Promise<void> => {
    day = parseInt(day.toString());
    let userCorrectiveExercise = await _getCurrentUserCorrectiveExercise(
        userId
    );
    _log(
        `finishDay() userId=${userId} day=${day} userCorrectiveExercise:`,
        userCorrectiveExercise
    );
    if (userCorrectiveExercise == null) {
        return;
    }

    if (userCorrectiveExercise.completedDateMs == null) {
        userCorrectiveExercise.completedDateMs = [];
    }

    if (userCorrectiveExercise.completedDateMs.length >= day) {
        // set existing index
        const index = day - 1;
        if (userCorrectiveExercise.completedDateMs[index] == null) {
            userCorrectiveExercise.completedDateMs[index] =
                DateUtil.getCurrentMsDate();
        }
    } else {
        // add date to array
        while (userCorrectiveExercise.completedDateMs.length < day) {
            const completedDayMs =
                userCorrectiveExercise.completedDateMs.length === day - 1
                    ? DateUtil.getCurrentMsDate()
                    : 0;
            userCorrectiveExercise.completedDateMs.push(completedDayMs);
        }
    }

    // increment day or finish
    // if (userCorrectiveExercise.routineDays === day) {
    //     UserUtil.setRetakeMovementAssessment(userId, true);
    // }

    // save changes
    UserCorrectiveExercisesService.update(
        userCorrectiveExercise,
        currentIdentity
    );

    // delete today cache
    // TodayService.deleteToday(userId);

    // add user activity
    FirebaseUtils.logUserActivity(userId, ActivityType.Completion);
};

/**
 * Marks the specified corrective exercise as complete
 * @param   {string} userId - User who is completing an exercise day
 * @param   {number} day - 1-based day to mark as complete
 * @returns {Promise<void>}
 */
const finishDayExtra = async (
    userId: string,
    day: number,
    currentIdentity?: IUser
): Promise<void> => {
    day = parseInt(day.toString());
    let userCorrectiveExercise = await _getCurrentUserExtraCorrectiveExercise(
        userId
    );
    _log(
        `finishDay() userId=${userId} day=${day} userCorrectiveExercise:`,
        userCorrectiveExercise
    );
    if (userCorrectiveExercise == null) {
        return;
    }

    if (userCorrectiveExercise.completedDateMs == null) {
        userCorrectiveExercise.completedDateMs = [];
    }

    if (userCorrectiveExercise.completedDateMs.length >= day) {
        // set existing index
        const index = day - 1;
        if (userCorrectiveExercise.completedDateMs[index] == null) {
            userCorrectiveExercise.completedDateMs[index] =
                DateUtil.getCurrentMsDate();
        }
    } else {
        // add date to array
        while (userCorrectiveExercise.completedDateMs.length < day) {
            const completedDayMs =
                userCorrectiveExercise.completedDateMs.length === day - 1
                    ? DateUtil.getCurrentMsDate()
                    : 0;
            userCorrectiveExercise.completedDateMs.push(completedDayMs);
        }
    }

    // increment day or finish
    // if (userCorrectiveExercise.routineDays === day) {
    //     UserUtil.setRetakeMovementAssessment(userId, true);
    // }

    // save changes
    UserCorrectiveExercisesService.update(
        userCorrectiveExercise,
        currentIdentity
    );

    // delete today cache
    console.log("deleting today")
    // TodayService.deleteToday(userId);

    // add user activity
    FirebaseUtils.logUserActivity(userId, ActivityType.Completion);
};

/**
 * Returns today's corrective exercise
 * @param    {string} userId - User who is getting an exercise
 * @returns {Promise<CorrectiveExercise | null>} A promise for the current corrective exercise
 */
const getTodaysExercise = async (
    userId: string,
    dayToShow?: number,
): Promise<CorrectiveExercise | null> => {
    let userCorrectiveExercise = await _getCurrentUserCorrectiveExercise(
        userId
    );
    console.log("userCorrectiveExercise",userCorrectiveExercise)
    _log(
        `getTodaysExercise("${userId}") existing userCorrectiveExercise:`,
        userCorrectiveExercise
    );

    // we don't want to create a new corrective if today's exercise is an extra
    // (road to wellness)
    if (userCorrectiveExercise == null) {
        const extra = await _getCurrentUserExtraCorrectiveExercise(userId);
        if (extra) {
            return null;
        }
    }

    // create UserCorrectiveExercise and return day 1
    if (userCorrectiveExercise == null) {
        // Commenting out for now as we no longer need to create correctives on today generation, but rather
        // on assessment completion/retake. Can be removed after tested and user-profile is deployed.
        // const createResult = await _createUserCorrectiveExercise(userId);
        // _log(`getTodaysExercise("${userId}") createResult:`, createResult);
        // const hasRoutine = createResult.hasRoutine;
        // const routine = createResult.routine;
        // userCorrectiveExercise = createResult.userCorrectiveExercise;

        // // return w/ day 1 exercise
        // if (hasRoutine) {
        //     routine.day1Exercise.routineDays = routine.routineDays;
        // }
        // if (routine.day1Exercise != null) {
        //     routine.day1Exercise.isComplete = false;
        // }

        // return routine.day1Exercise;
        return null;
    }



    // validate that the user currently has a routine
    if (userCorrectiveExercise.routineId == null) {
        return null;
    }

    // Account for missed days
    const wellnessProgram = await WellnessProgramService.getCurrentByUserId(userId);
    let exerciseDay: number | undefined;

    if (wellnessProgram.length !== 0 && wellnessProgram[0]) {
        exerciseDay = dayToShow !== undefined ? dayToShow : wellnessProgram[0].currentDay;

        if ((userCorrectiveExercise.completedDateMs.length + 1) !== wellnessProgram[0].currentDay) {
            const missedDays = exerciseDay - 1 - userCorrectiveExercise.completedDateMs.length;

            if (missedDays > 0) {
                for (let i = 0; i < missedDays; i++) {
                    if (userCorrectiveExercise.completedDateMs.length === userCorrectiveExercise.routineDays) {
                        break;
                    }
                    userCorrectiveExercise.completedDateMs.push(0);
                }

                await UserCorrectiveExercisesService.update(userCorrectiveExercise);
            }
        }
    }
    // returning null because user has no wellness program
    else {
        console.log("returning early, returning nothing since user can't have correctives without wellness program")
        return null;
    }

    // get exercise
    const wasCompletedToday = _wasCompletedToday(userCorrectiveExercise);
    // const exerciseDay: number = wasCompletedToday
    //     ? userCorrectiveExercise.completedDateMs.length
    //     : 1 + userCorrectiveExercise.completedDateMs.length;
    // mark complete
    if (
        _canMarkNotCurrent(
            wasCompletedToday,
            userCorrectiveExercise.completedDateMs,
            userCorrectiveExercise.routineDays
        )
    ) {
        // This if is only meant for any day after the user completed their final corrective exercise (7/7)

        // console.log("inside canMarkNotCurrent");
        // // if (userCorrectiveExercise.isCurrent) {
        // //     userCorrectiveExercise.isCurrent = false;
        // //     UserCorrectiveExercisesService.update(userCorrectiveExercise);
        // // }
        // console.log("returning early")
        return null;
    }

    const exerciseInput = {
        routineId: userCorrectiveExercise.routineId,
        day: exerciseDay,
        videoSize: VIDEO_SIZE,
    };
    const exercise = await CorrectiveExercisesService.getExercise(
        exerciseInput
    );
    if (exercise.errors != null && exercise.errors.length >= 1) {
        exercise.errors = [
            `Error loading videos for Exercise Routine ${exerciseInput.routineId
            } Day ${exerciseInput.day}: ${exercise.errors.join("; ")}`,
        ];
    }
    exercise.routineDays = userCorrectiveExercise.routineDays;
    // If dayToShow is different from the current day (e.g. it's the next day) then don't show the exercise as complete
    exercise.isComplete = (!dayToShow || dayToShow === wellnessProgram[0].currentDay) ? wasCompletedToday : false;
    return exercise;
};

/**
 * Returns today's extra corrective exercise
 * @param    {string} userId - User who is getting an exercise
 * @returns {Promise<CorrectiveExercise | null>} A promise for the current corrective exercise
 */
const getTodaysExtraExercise = async (
    userId: string,
    dayToShow?: number,
): Promise<CorrectiveExercise | null> => {
    let userCorrectiveExercise = await _getCurrentUserExtraCorrectiveExercise(
        userId
    );

    _log(
        `getTodaysExtraExercise("${userId}") existing userCorrectiveExercise:`,
        userCorrectiveExercise
    );

    // create UserCorrectiveExercise and return day 1
    if (userCorrectiveExercise == null) {
        // Commenting out for now as we no longer need to create correctives on today generation, but rather
        // on assessment completion/retake. Can be removed after tested and user-profile is deployed.
        // const createResult = await _createUserExtraCorrectiveExercise(userId);
        // _log(`getTodaysExtraExercise("${userId}") createResult:`, createResult);
        // const hasRoutine = createResult.hasRoutine;
        // const routine = createResult.routine;
        // userCorrectiveExercise = createResult.userCorrectiveExercise;

        // // return w/ day 1 exercise
        // if (hasRoutine) {
        //     routine.day1Exercise.routineDays = routine.routineDays;
        // }
        // if (routine.day1Exercise != null) {
        //     routine.day1Exercise.isComplete = false;
        // }

        // return routine.day1Exercise;
        return null;
    }

    // validate that the user currently has a routine
    if (userCorrectiveExercise.routineId == null) {
        return null;
    }

    // Account for missed days
    const wellnessProgram = await WellnessProgramService.getCurrentByUserId(userId);
    let exerciseDay: number | undefined;

    if (wellnessProgram.length !== 0 && wellnessProgram[0]) {
        exerciseDay = dayToShow !== undefined ? dayToShow : wellnessProgram[0].currentDay;

        if ((userCorrectiveExercise.completedDateMs.length + 1) !== wellnessProgram[0].currentDay) {
            const missedDays = exerciseDay - 1 - userCorrectiveExercise.completedDateMs.length;

            if (missedDays > 0) {
                for (let i = 0; i < missedDays; i++) {
                    if (userCorrectiveExercise.completedDateMs.length === userCorrectiveExercise.routineDays) {
                        break;
                    }
                    userCorrectiveExercise.completedDateMs.push(0);
                }

                await UserCorrectiveExercisesService.update(userCorrectiveExercise);
            }
        }
    }
    else {
        return null;
    }

    // get exercise
    const wasCompletedToday = _wasCompletedToday(userCorrectiveExercise);
    // const exerciseDay: number = wasCompletedToday
    //     ? userCorrectiveExercise.completedDateMs.length
    //     : 1 + userCorrectiveExercise.completedDateMs.length;

    // mark complete
    if (
        _canMarkNotCurrent(
            wasCompletedToday,
            userCorrectiveExercise.completedDateMs,
            userCorrectiveExercise.routineDays
        )
    ) {
        // if (userCorrectiveExercise.isCurrent) {
        //     userCorrectiveExercise.isCurrent = false;
        //     UserCorrectiveExercisesService.update(userCorrectiveExercise);
        // }
        return null;
    }

    const exerciseInput = {
        routineId: userCorrectiveExercise.routineId,
        day: exerciseDay,
        videoSize: VIDEO_SIZE,
    };
    const exercise = await CorrectiveExercisesService.getExercise(
        exerciseInput
    );
    if (exercise.errors != null && exercise.errors.length >= 1) {
        exercise.errors = [
            `Error loading videos for Exercise Routine ${exerciseInput.routineId
            } Day ${exerciseInput.day}: ${exercise.errors.join("; ")}`,
        ];
    }
    exercise.routineDays = userCorrectiveExercise!.routineDays;
    // If dayToShow is different from the current day (e.g. it's the next day) then don't show the exercise as complete
    exercise.isComplete = (!dayToShow || dayToShow === wellnessProgram[0].currentDay) ? wasCompletedToday : false;

    return exercise;
};

/**
 * When a new movement score has been added, this changes the state of UserCorrectiveExercise
 * @param    {MovementScore} movementScore - new movement score
 * @param    {IUser} currentIdentity - user with the movement score
 * @returns {Promise<void>}
 */
const handleNewMovementScore = async (
    movementScore: MovementScore,
    currentIdentity: IUser
): Promise<void> => {
    // validate inputs
    if (currentIdentity?.id == null || movementScore?.percentage == null) {
        _log(
            `handleNewMovementScore() abort changes: userId=${currentIdentity?.id} movement%=${movementScore?.percentage}`
        );
        return;
    }

    // load current corrective exercise
    let currentCorrective = await _getCurrentUserCorrectiveExercise(
        currentIdentity.id
    );
    if (currentCorrective == null) {
        _log(
            `handleNewMovementScore() no current corrective for "${currentIdentity?.id}"`
        );
        return;
    }

    // end current corrective routine if focus is different
    const correctiveInput: CorrectiveRoutineInput = {
        focusArea: movementScore.focusArea!,
        videoSize: VideoSize.LANDSCAPE_180P,
    };
    const newRoutine = await CorrectiveExercisesService.findRoutine(
        correctiveInput
    );
    _log(
        `handleNewMovementScore() currentCorrective.routineId=${currentCorrective.routineId} newRoutine.routineId=${newRoutine?.routineId} newRoutine:`,
        newRoutine
    );
    if (
        newRoutine == null ||
        newRoutine.routineId !== currentCorrective.routineId
    ) {
        _log(`handleNewMovementScore() turn off`);
        currentCorrective.isCurrent = false;
        await UserCorrectiveExercisesService.update(
            currentCorrective,
            currentIdentity
        );
    }
};

/**
 * When a new movement score has been added, this changes the state of UserCorrectiveExercise
 * @param    {MovementScore} movementScore - new movement score
 * @param    {IUser} currentIdentity - user with the movement score
 * @returns {Promise<void>}
 */
const handleNewMovementScoreById = async (
    movementScore: MovementScore,
    currentUserId: string
): Promise<void> => {
    // validate inputs
    if (currentUserId == null || movementScore?.percentage == null) {
        _log(
            `handleNewMovementScore() abort changes: userId=${currentUserId} movement%=${movementScore?.percentage}`
        );
        return;
    }

    // load current corrective exercise
    let currentCorrective = await _getCurrentUserCorrectiveExercise(
        currentUserId
    );
    if (currentCorrective == null) {
        _log(
            `handleNewMovementScore() no current corrective for "${currentUserId}"`
        );
        return;
    }

    // end current corrective routine if focus is different
    const correctiveInput: CorrectiveRoutineInput = {
        focusArea: movementScore.focusArea!,
        videoSize: VideoSize.LANDSCAPE_180P,
    };
    const newRoutine = await CorrectiveExercisesService.findRoutine(
        correctiveInput
    );
    _log(
        `handleNewMovementScore() currentCorrective.routineId=${currentCorrective.routineId} newRoutine.routineId=${newRoutine?.routineId} newRoutine:`,
        newRoutine
    );
    if (
        newRoutine == null ||
        newRoutine.routineId !== currentCorrective.routineId
    ) {
        _log(`handleNewMovementScore() turn off`);
        currentCorrective.isCurrent = false;
        await UserCorrectiveExercisesService.updateById(
            currentCorrective,
            currentUserId
        );
    }
};

const getExerciseDuration = (exercise: CorrectiveExercise) => {
    if (exercise?.videos == null || exercise.videos.length === 0) {
        return 3; // default
    }
    let totalSeconds = 0;
    exercise.videos.forEach((video) => {
        if (video.duration != null) {
            totalSeconds += video.duration;
        }
    });

    return Math.round(Math.ceil(totalSeconds / 60));
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const _log = (message?: any, ...optionalParams: any[]): void => {
    if (false) {
        console.log(message, ...optionalParams);
    }
};

const _canMarkNotCurrent = (
    wasCompletedToday: boolean,
    completedDateMs: number[],
    routineDays: number
): boolean => {
    if (wasCompletedToday) {
        return false;
    }
    if (completedDateMs == null || completedDateMs.length === 0) {
        return false;
    }
    return completedDateMs.length >= routineDays;
};

const _createUserCorrectiveExercise = async (userId: string): Promise<any> => {
    const mskScore = await MskScoreUtil.getLatest(userId);

    if (mskScore?.movementScore?.percentage == null) {
        _log("_createUserCorrectiveExercise(): movement was not completed");
        return null;
    }

    // determine routine
    let focusArea = mskScore.movementScore.focusArea;
    if (focusArea == null && mskScore.movementScore.percentage === 100) {
        focusArea = {
            groupId: GroupIds.MOVEMENT_HEALTH,
            groupName: undefined,
            percentage: 100,
            score: 3,
        };
    }
    const correctiveInput: CorrectiveRoutineInput = {
        focusArea: focusArea,
        videoSize: VIDEO_SIZE,
    };
    _log("_createUserCorrectiveExercise(): correctiveInput:", correctiveInput);
    const routine = await CorrectiveExercisesService.findRoutine(
        correctiveInput
    );
    _log("_createUserCorrectiveExercise(): routine:", routine);
    const hasRoutine: boolean = routine != null && routine.routineId != null;

    // add UserCorrectiveExercise
    let userCorrectiveExercise: UserCorrectiveExercise = {
        userId: userId,
        routineDays: hasRoutine ? routine.routineDays : 100,
        isCurrent: true,
        completedDateMs: [],
    };
    if (hasRoutine) {
        userCorrectiveExercise.routineId = routine.routineId;
    }

    userCorrectiveExercise = await UserCorrectiveExercisesService.add(
        userCorrectiveExercise
    );

    return {
        hasRoutine,
        routine,
        userCorrectiveExercise,
    };
};

const _createUserExtraCorrectiveExercise = async (userId: string): Promise<any> => {
    const mskScore = await MskScoreUtil.getLatest(userId);

    if (mskScore?.movementScore?.percentage == null) {
        _log("_createUserExtraCorrectiveExercise(): movement was not completed");
        return null;
    }

    // determine routine
    let focusArea = mskScore.movementScore.focusArea;
    if (focusArea == null && mskScore.movementScore.percentage === 100) {
        focusArea = {
            groupId: GroupIds.MOVEMENT_HEALTH,
            percentage: 100,
            score: 3,
        };
    }
    const correctiveInput: CorrectiveRoutineInput = {
        focusArea: focusArea,
        videoSize: VIDEO_SIZE,
        existingMskScore: mskScore,
    };
    _log("_createUserExtraCorrectiveExercise(): correctiveInput:", correctiveInput);
    const routine = await CorrectiveExercisesService.findExtraRoutine(
        correctiveInput
    );
    _log("_createUserExtraCorrectiveExercise(): routine:", routine);
    const hasRoutine: boolean = routine != null && routine.routineId != null;

    // add UserCorrectiveExercise
    let userCorrectiveExercise: UserCorrectiveExercise = {
        userId: userId,
        routineDays: hasRoutine ? routine.routineDays : 100,
        isCurrent: true,
        completedDateMs: [],
    };
    if (hasRoutine) {
        userCorrectiveExercise.routineId = routine.routineId;
    }

    userCorrectiveExercise = await UserCorrectiveExercisesService.add(
        userCorrectiveExercise
    );

    return {
        hasRoutine,
        routine,
        userCorrectiveExercise,
    };
};

const _wasCompletedToday = (
    userCorrectiveExercises: UserCorrectiveExercise | null
): boolean => {
    if (
        userCorrectiveExercises?.completedDateMs == null ||
        userCorrectiveExercises.completedDateMs.length === 0
    ) {
        return false;
    }

    const lastCompletedMs =
        userCorrectiveExercises.completedDateMs[
        userCorrectiveExercises.completedDateMs.length - 1
        ];
    return DateUtil.isToday(lastCompletedMs);
};

const _getCurrentUserCorrectiveExercise = async (
    userId: string
): Promise<UserCorrectiveExercise | null> => {
    let userCorrectiveExercise: UserCorrectiveExercise | null = null;

    try {
        const userCorrectiveExercises =
            await UserCorrectiveExercisesService.getCurrentByUserId(userId);
        if (
            userCorrectiveExercises != null &&
            userCorrectiveExercises.length >= 1
        ) {
            userCorrectiveExercise = userCorrectiveExercises[0];
        }
    } catch (e) {
        console.error(
            `ERROR: Could not load user "${userId}" corrective exercise:`,
            e
        );
    }

    return userCorrectiveExercise;
};

const _getCurrentUserExtraCorrectiveExercise = async (
    userId: string
): Promise<UserCorrectiveExercise | null> => {
    let userCorrectiveExercise: UserCorrectiveExercise | null = null;

    try {
        const userCorrectiveExercises =
            await UserCorrectiveExercisesService.getCurrentExtraByUserId(userId);
        if (
            userCorrectiveExercises != null &&
            userCorrectiveExercises.length >= 1
        ) {
            userCorrectiveExercise = userCorrectiveExercises[0];
        }
    } catch (e) {
        console.error(
            `ERROR: Could not load user "${userId}" extra corrective exercise:`,
            e
        );
    }
    return userCorrectiveExercise;
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const CorrectiveExerciseUtils = {
    EXTRA_ID,
    finishDay,
    finishDayExtra,
    getTodaysExercise,
    getTodaysExtraExercise,
    handleNewMovementScore,
    handleNewMovementScoreById,
    getExerciseDuration,
    _createUserCorrectiveExercise,
    _createUserExtraCorrectiveExercise
};

// #endregion Exports
