export enum ActivityType {
    Open = "open",
    Completion = "completion",
    ArticleCompletion = "article completion",
    MovementAssessment = "movement assessment",
    LifestyleAssessment = "lifestyle assessment",
    CorrectiveCompletion = "corrective completion",

    WebMovementAssessment = "web movement assessment",
    WebLifestyleAssessment = "web lifestyle assessment",

    AppMovementHealth = "movement health retake",
    
    AppBehavioralHealth = "behavioral health retake",
    AppBodyComposition = "body composition retake",
    AppBreathingQuality = "breathing quality retake",
    AppInjuryHistory = "injury history retake",
    AppNutritionAwareness = "nutrition awareness retake",
    AppPhysicalActivity = "physical activity retake",
    AppSleepWellness = "sleep wellness retake",

    FullMskRetake = "full msk retake",
    FullMskRetakePortal = "full msk retake portal",
    
    TodayCompleted = "today completed",

    AdministeredRetake = "administered retake"
}
