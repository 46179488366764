/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import UserUtil from "../../../utilities/user-util";
import { RadioGroupItem } from "../../radio-group/radio-group-item";
import { PlanFields } from "./plan-fields";
import { PlanType } from "../../../models/enumerations/plan-type";
import { DefaultFields } from "./default-fields";
import { OrganizationType } from "../../../models/enumerations/organization-type";
import { OrganizationSubscription } from "../../../models/interfaces/organization-subscription";
import { Organization } from "../../../models/interfaces/organization";
import { useAuthState } from "../../../utilities/contexts/auth-state-context";
import OrganizationService from "../../../utilities/services/organization-service";
import OrganizationSubscriptionService from "../../../utilities/services/organization-subscription-service";
import moment, { Moment } from "moment";
import { OrganizationFormValues, PartialOrgFormValues, TrialOrgFormValues } from "./form-values";
import EditOrganizationValidationRules from "./edit-organization-validation";
import { User } from "../../../models/interfaces/user";
import { UserStatus } from "../../../models/enumerations/user-status";
import UserService from "../../../utilities/services/user-service";
import { useHistory } from "react-router";
import UserRoles from "../../../models/user-roles";
import OrganizationUtil from "../../../utilities/organization-util";
import { Switch, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { OrganizationStatusBadge } from "../../organization-status-badge/organization-status-badge";
import { OrganizationStatus } from "../../../models/enumerations/organization-status";
import { FormikRadioGroup } from "../../formik-fields/formik-radio-group";
import { PaymentTypeOptions } from "../../../models/payment-type";
import UniqueUserInviteLinksService from "../../../utilities/services/unique-user-invite-link-service";
import { UniqueUserInviteLink } from "../../../models/interfaces/unique-user-invite-link";
import { PaymentType } from "../../../models/enumerations/payment-type";
import { Timestamp, deleteField, doc, updateDoc, getFirestore } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { AppContentType } from "../../../models/enumerations/app-content-type";
import { AppContent } from "../../../models/interfaces/app-content";
//import FunctionService from "../../../utilities/services/function-service";
import { SymmioAccessType } from "../../../models/enumerations/symmio-access-type";
import { Button } from "../../button/button";
import Stripe from "stripe";

type EditOrganizationFormProps = {
    organizationId?: string,
    redirect?: string,
    onSave?: Function,
    onCancel?: Function,
};

const accountTypeRadioItems: RadioGroupItem[] = [
    {
        value: OrganizationType.Paid,
        label: OrganizationType[OrganizationType.Paid],
    },
    {
        value: OrganizationType.Trial,
        label: OrganizationType[OrganizationType.Trial],
    },
];

const trialPlanObject = {
    value: PlanType.Trial,
    label: PlanType[PlanType.Trial],
}

export const EditOrganizationForm: React.FC<EditOrganizationFormProps> = (props: EditOrganizationFormProps) => {
    const { organizationId, redirect, onSave, onCancel } = props;
    const { state } = useAuthState();
    const history = useHistory();
    const user = state.user;
    const isAccountHolder = UserUtil.isAccountHolder(user);

    const [accountHolderName, setAccountHolderName] = useState<string>();
    const [accountType, setAccountType] = useState<RadioGroupItem>(accountTypeRadioItems[0]);
    const [active, setActive] = useState<boolean>(true);
    const [expired, setExpired] = useState<boolean>();
    const [initCustomTrialLength, setInitCustomTrialLength] = useState<boolean>(false);
    const [initialForm, setInitialForm] = useState<OrganizationFormValues | PartialOrgFormValues | TrialOrgFormValues | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [organization, setOrganization] = useState<Organization>();
    const [planType, setPlanType] = useState<PlanType | null>(null);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>();
    const [subscriptionId, setSubscriptionId] = useState<string>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [testOrg, setTestOrg] = useState<boolean>(state.user ? UserUtil.isDeveloperUser(state.user) : false);
    const [userCount, setUserCount] = useState<number | string>('');
    const [userLimit, setUserLimit] = useState<number | string>('');
    const [newFormTimeout, setNewFormTimeout] = useState<boolean>(true);
    const db = getFirestore();

    setTimeout(() => setNewFormTimeout(false), 100)
    // #region UseEffects

    const getHolderName = (holder: User) => {
        let name = "";
        if (holder.firstName || holder.lastName) {
            if (holder.firstName) {
                name += holder.firstName;
            }
            if (holder.lastName) {
                if (holder.firstName) name += ' ';
                name += holder.lastName;
            }
        } else if (holder.email) {
            name += holder.email;
        }

        // if we get this far and there's no useable name, we'll just use the
        // id, but there should always be at least an email
        if (name === "" && holder.id) {
            return `id: ${holder.id}`
        }

        return name;
    };

    const isTrialOrg = (status: any) => {
        if (typeof status === 'string') {
            if (status === OrganizationType.Trial.toString()) {
                return true;
            }
        }

        if (typeof status === 'number') {
            if (status === OrganizationType.Trial) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            if (organizationId) {
                // get the organization being edited
                const o = await OrganizationService.get(organizationId);

                const temp = await OrganizationSubscriptionService.getByOrganizationId(organizationId);
                const orgSub = temp[0];

                let accHolderName = '';

                if (o && o.id) {
                    setOrganization(o);

                    setActive(o.active !== false);

                    if (o.testingOrg === true) {
                        setTestOrg(true);
                    } else {
                        setTestOrg(false);
                    }

                    if (isTrialOrg(orgSub.accountType.value)) {
                        // trial account type
                        setAccountType(accountTypeRadioItems[1]);
                    } else {
                        // paid account type
                        setAccountType(accountTypeRadioItems[0]);
                    }

                    const uCount: number = await OrganizationUtil.getOrganizationUserCount(o, true);
                    setUserCount(uCount ?? '??');
                    setUserLimit(o.userLimit ?? 'no limit');

                    if (o.accountHolderId) {
                        const holder = await UserService.get(o.accountHolderId);
                        if (holder) {
                            const holderName = getHolderName(holder);
                            setAccountHolderName(holderName);
                            accHolderName = holderName;
                        } else {
                            setAccountHolderName('n/a');
                            accHolderName = 'n/a';
                        }
                    } else {
                        setAccountHolderName('n/a');
                        accHolderName = 'n/a';
                    }

                    if (!isTrialOrg(orgSub.accountType.value)) {
                        // try to get the organization subscription
                        const subRes = await OrganizationSubscriptionService.getByOrganizationId(o.id);

                        if (subRes && subRes.length > 0 && subRes[0].id) {
                            const sub: OrganizationSubscription = subRes[0];
                            setSubscriptionId(sub.id);
                            setPlanType(sub?.plan?.value!);
                            // set initial form values
                            const init: OrganizationFormValues = {
                                accountType: sub.accountType,
                                organizationName: o.name ?? '',
                                accountHolderName: accHolderName,
                                plan: sub.plan!,
                                paymentCycle: sub.paymentCycle!,
                                userLimit: sub.userLimit,
                                startDate: moment(sub.startDate),
                                renewDate: sub.renewDate ? moment(sub.renewDate) : moment().add(1, 'month'),
                                availableOrganizations: sub.organizationLimit,
                                mySymmioLicense: sub.mySymmioLicense,
                                subscriptionName: sub.subscriptionName ?? '',
                                mySymmioYearlyPrice: sub.mySymmioYearlyPrice ?? 0,
                                mySymmioMonthlyPrice: sub.mySymmioMonthlyPrice ?? 0,
                                accountManager: o.accountManager,
                            }
                            if (sub.paymentType) init.paymentType = sub.paymentType;
                            if (sub.price || sub.price === 0) init.price = sub.price;
                            if (sub.pricePerUser) init.pricePerUser = sub.pricePerUser;

                            const exp = moment(sub.renewDate).isSameOrBefore(moment());
                            setExpired(exp);

                            setInitialForm(init);
                        } else {
                            // this will be the case for organizations created or
                            // last edited before this new organization creation
                            // form was made
                            let accType = accountTypeRadioItems[0];
                            const temp = await OrganizationSubscriptionService.getByOrganizationId(o.id);
                            const orgSub = temp[0];
                            if (orgSub.accountType.value === OrganizationType.Trial) {
                                accType = accountTypeRadioItems[1];
                            }

                            setActive(o.status !== OrganizationStatus.Deactivated)

                            if (typeof o.status === 'string') {
                                if (orgSub.accountType.value === OrganizationType.Trial) {
                                    accType = accountTypeRadioItems[1];
                                }

                                setActive(o.status !== OrganizationStatus.Deactivated.toString())
                            }

                            let startDate = moment();
                            if (o.created) {
                                const timestamp = o.created as Timestamp;
                                if (timestamp) {
                                    startDate = moment(timestamp.seconds, 'X');
                                }
                            }

                            setInitialForm({
                                accountType: accType,
                                organizationName: o.name ?? '',
                                accountHolderName: accHolderName ?? '',
                                startDate,
                            });
                        }
                        // this will be the case for trial organizations
                    } else {
                        if (o.accountHolderId) {
                            const holder = await UserService.get(o.accountHolderId);
                            if (holder) {
                                const holderName = getHolderName(holder);
                                setAccountHolderName(holderName);
                                accHolderName = holderName;
                            } else {
                                setAccountHolderName('n/a');
                                accHolderName = 'n/a';
                            }
                        } else {
                            setAccountHolderName('n/a');
                            accHolderName = 'n/a';
                        }

                        const orgSubRes = await OrganizationSubscriptionService.getByOrganizationId(o.id);

                        // this is the case for new trial organizations
                        if (orgSubRes && orgSubRes.length > 0) {
                            setSubscriptionId(orgSubRes[0].id);
                            const orgSub = orgSubRes[0];
                            const startDate = moment(orgSub.startDate);
                            const expDate = moment(orgSub.renewDate);
                            const trialLength = orgSub.trialLength;
                            setStartDate(startDate.format('MM/DD/yyyy'));

                            const init: TrialOrgFormValues = {
                                accountType: orgSub.accountType,
                                organizationName: o.name ?? '',
                                accountHolderName: accHolderName,
                                userLimit: orgSub.userLimit,
                                mySymmioLicense: orgSub.mySymmioLicense,
                                availableOrganizations: orgSub.organizationLimit,
                                price: 0,
                                startDate,
                                renewDate: expDate,
                                trialLength: trialLength,
                                accountManager: o.accountManager,
                            }

                            if (trialLength && trialLength.value === 0) {
                                init.customTrialLength = orgSub.customTrialLength;
                                setInitCustomTrialLength(true);
                            }

                            const isExpired = expDate.isSameOrBefore(moment());
                            setExpired(isExpired);

                            setInitialForm(init);
                            // this is the case for old trial organizations
                        } else {
                            const expDate = moment(o.expiration);

                            const isExpired = expDate.isSameOrBefore(moment());
                            setExpired(isExpired);

                            const init: TrialOrgFormValues = {
                                accountType: accountTypeRadioItems[0],
                                organizationName: o.name ?? '',
                                accountHolderName: accHolderName,
                                userLimit: o.userLimit ?? 1,
                                price: 0,
                                renewDate: moment(o.expiration),
                                availableOrganizations: o.organizationLimit ?? 1,
                                accountManager: o.accountManager,
                            }

                            setInitialForm(init);
                        }
                    }

                }
            }
            setLoading(false);
        }

        load();
    }, [organizationId, refresh]);

    useEffect(() => {
        setRefresh(true);
        setRefresh(false);
    }, [])
    // #endregion UseEffects

    // #region Handlers

    const getOrganizationStatus = () => {
        if (!organization) {
            return OrganizationStatus.Active;
        }

        const status = organization.status;

        if (typeof status === 'string') {
            if (status === OrganizationStatus.Deactivated.toString() || organization.active === false) {
                return OrganizationStatus.Deactivated;
            }
            if (status === OrganizationStatus.Active.toString()) {
                return OrganizationStatus.Active;
            }
            if (status === OrganizationStatus.Expired.toString()) {
                return OrganizationStatus.Expired;
            }
        } else {
            if (status === OrganizationStatus.Deactivated || organization.active === false) {
                return OrganizationStatus.Deactivated
            }
            if (status === OrganizationStatus.Active) {
                return OrganizationStatus.Active;
            }
            if (status === OrganizationStatus.Expired) {
                return OrganizationStatus.Expired;
            }
        }

        return OrganizationStatus.Active;
    }

    const getValidation = () => {
        if (accountType === accountTypeRadioItems[0]) {
            return EditOrganizationValidationRules.PaidOrganizationValidationRules();
        }

        return EditOrganizationValidationRules.TrialOrganizationValidationRules();
    };

    const handleAccountTypeChange = (option: RadioGroupItem) => {
        setAccountType(option);
    }

    // #endregion Handlers

    const saveOrganization = async (values: OrganizationFormValues) => {
        setSubmitting(true);
        console.log(values);
        let org: Organization;
        let initialPlan;
        // if an org is being edited, begin with its existing data
        if (organization) {
            org = { ...organization }
            initialPlan = org.plan
            const isExpired = values.renewDate ? moment(values.renewDate).isSameOrBefore(moment()) : false;

            // overwrite any data changed by the form
            if (!org.appContent) org.appContent = {
                [AppContentType.PAIN]: {} as AppContent,
                [AppContentType.BEHAVIORAL_HEALTH]: {} as AppContent,
            }
            org.name = values.organizationName.trim();
            org.userLimit = values.userLimit;
            org.active = active;
            org.mySymmioLicense = Number(values.mySymmioLicense);
            org.plan = isTrialOrg(values?.accountType?.value) ? trialPlanObject.label : (values?.plan?.label || "");
            org.status = active ? (isExpired ? OrganizationStatus.Expired : OrganizationStatus.Active) : OrganizationStatus.Deactivated;
            org.accountManager = values.accountManager;
        } else {
            org = {
                appContent: {
                    [AppContentType.PAIN]: {} as AppContent,
                    [AppContentType.BEHAVIORAL_HEALTH]: {} as AppContent,
                },
                status: OrganizationStatus.Active,
                name: values.organizationName.trim(),
                userLimit: values.userLimit,
                mySymmioLicense: Number(values.mySymmioLicense),
                accountManager: values.accountManager,
            };
            org.plan = isTrialOrg(values.accountType?.value) ? trialPlanObject.label : values.plan.label;
        }

        if (planType === PlanType.Enterprise || accountType.value === OrganizationType.Trial) {
            org.organizationLimit = values.availableOrganizations;
        } else {
            org.organizationLimit = 1;
        }

        if (testOrg === true) {
            org.testingOrg = true;
        }

        // if an account holder is creating this organization, set the parent
        // org id
        if (isAccountHolder && user?.organizationId) {
            org.accountHolderId = user.organizationId;
        }

        if (values.accountType.value === OrganizationType.Trial) {
            const expiryDate = values.renewDate as Moment;
            org.expiration = expiryDate.toISOString();
        }

        let savedOrg = await OrganizationService.save(org, user);
        setOrganization(savedOrg);

        let holderExists = false;

        if (values.accountHolderEmail) {
            const oldHolder = await UserService.getBy([{
                field: 'email',
                operator: '==',
                value: values.accountHolderEmail,
            }]);

            holderExists = oldHolder.length > 0;
        }

        // if creating a new organization, we need to create the account holder
        if (savedOrg && savedOrg.id && !organization && !holderExists) {
            const accHolder: User = {
                email: values.accountHolderEmail?.toLowerCase(),
                status: UserStatus.Inactive,
                isSuperAdmin: false,
                organizationId: savedOrg.id,
                roles: [UserRoles.ACCOUNT_HOLDER_ID],
                symmioAccess: SymmioAccessType.AppLicense,
                notificationSettings: {
                    inactiveUser: true,
                    highRiskUser: true,
                    dayStreakMilestone: true,
                    newAccount: true,
                    completedTasks: true,
                    baselineAssessment: true,
                }
            };

            const savedHolder = await UserService.add(accHolder, user);

            // we now need to save the org again to apply the account holder id
            if (savedHolder && savedHolder.id && values.renewDate) {
                const date = new Date(values.renewDate.toLocaleString());
                const uniqueInviteLink: UniqueUserInviteLink = {
                    userId: savedHolder.id,
                    senderEmail: state.user?.email,
                    organizationId: state.organization.id,
                    isTrial: values.accountType.value === OrganizationType.Trial,
                    trialLength: values.accountType.value === OrganizationType.Trial ? values.trialLength?.value : undefined,
                    trialEndDate: values.accountType.value === OrganizationType.Trial ? date.toLocaleString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    }) : undefined,
                };

                await UniqueUserInviteLinksService.save(uniqueInviteLink, state.user);

                savedOrg.accountHolderId = savedHolder.id;
                savedOrg = await OrganizationService.save(savedOrg, user);
                setOrganization(savedOrg);
            }
        }

        if (savedOrg && accountHolderName === 'n/a' && values.accountHolderId && values.accountHolderId.value !== 'nothingOption') {
            const hUser = await UserService.get(values.accountHolderId.value);
            if (hUser) {
                hUser.roles = [UserRoles.ACCOUNT_HOLDER_ID];
                await UserService.save(hUser);
                savedOrg.accountHolderId = values.accountHolderId.value;
                savedOrg = await OrganizationService.save(savedOrg);
            }
        }


        if (savedOrg && savedOrg.id) {
            // trial subscriptions follow a different pattern, so we'll handle
            // that in the else
            if (values.accountType.value !== OrganizationType.Trial) {
                let startDate: string | Moment = values.startDate as Moment;
                startDate = startDate.toISOString();
                let renewDate: string | Moment = values.renewDate as Moment;
                renewDate = renewDate.toISOString();

                const orgSub: OrganizationSubscription = {
                    organizationId: savedOrg.id,
                    accountType: values.accountType,
                    plan: values.plan,
                    paymentCycle: values.paymentCycle,
                    price: 0,
                    userLimit: values.userLimit,
                    startDate: startDate,
                    renewDate: renewDate,
                    mySymmioLicense: Number(values.mySymmioLicense),
                    organizationLimit: 1,
                };
                console.log(orgSub);
                if (orgSub.plan && orgSub.plan.value === PlanType.Enterprise) {
                    orgSub.paymentType = values.paymentType;

                    if (values.paymentType?.value === PaymentType.FlatFee) {
                        orgSub.price = values.price;
                    } else {
                        orgSub.pricePerUser = values.pricePerUser;
                    }

                    orgSub.organizationLimit = values.availableOrganizations ?? 1;
                } else if (orgSub.plan && orgSub.plan.value === PlanType.Business) {
                    orgSub.price = values.price;
                } else if (orgSub.plan && orgSub.plan.value === PlanType.ThirdParty && values.subscriptionName && values.mySymmioMonthlyPrice !== undefined && values.mySymmioYearlyPrice !== undefined) {
                    // Make sure we're not creating a new product and prices again on edit
                    if (!orgSub.thirdPartyProductId && !orgSub.mySymmioYearlyPriceId && !orgSub.mySymmioMonthlyPriceId) {
                        const stripe: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);

                        orgSub.subscriptionName = values.subscriptionName;
                        orgSub.mySymmioYearlyPrice = Number(values.mySymmioYearlyPrice);
                        orgSub.mySymmioMonthlyPrice = Number(values.mySymmioMonthlyPrice);

                        const product = await stripe.products.create({
                            name: orgSub.subscriptionName,
                        });

                        const monthlyPrice = await stripe.prices.create({
                            currency: 'usd',
                            unit_amount: (orgSub.mySymmioMonthlyPrice * 100),
                            recurring: {
                                interval: 'month',
                            },
                            product: product.id,
                        });

                        const yearlyPrice = await stripe.prices.create({
                            currency: 'usd',
                            unit_amount: (orgSub.mySymmioYearlyPrice * 100),
                            recurring: {
                                interval: 'year',
                            },
                            product: product.id,
                        });

                        orgSub.thirdPartyProductId = product.id;
                        orgSub.mySymmioMonthlyPriceId = monthlyPrice.id;
                        orgSub.mySymmioYearlyPriceId = yearlyPrice.id;
                    }
                }

                if (subscriptionId) orgSub.id = subscriptionId;

                if (testOrg) {
                    orgSub.testingSub = true;
                }

                await OrganizationSubscriptionService.save(orgSub, user);

                // If we just set the orgSub to a starter, we need to delete the renewDate field from the document
                if (values.plan.value === PlanType.Starter && initialPlan && initialPlan !== PlanType[PlanType.Trial]) {
                    const orgSubRef = doc(db, "organizationSubscriptions", orgSub.id!);
                    await updateDoc(orgSubRef, {
                        renewDate: deleteField()
                    });
                }
                // If we set the plan to start && it was a Trial, we need to remove more fields
                else if (values.plan.value === PlanType.Starter && initialPlan && initialPlan === PlanType[PlanType.Trial]) {
                    // Delete expiration field
                    const orgRef = doc(db, "organizations", orgSub.organizationId);
                    await updateDoc(orgRef, {
                        expiration: deleteField()
                    });

                    // Delete Trial Length field
                    const orgSubRef = doc(db, "organizationSubscriptions", orgSub.id!);
                    await updateDoc(orgSubRef, {
                        trialLength: deleteField(),
                        renewDate: deleteField()
                    });
                }
            } else {
                const expMoment = values.renewDate as Moment;
                const expStr = expMoment.toISOString();

                const orgSub: OrganizationSubscription = {
                    organizationId: savedOrg.id,
                    accountType: values.accountType,
                    userLimit: values.userLimit,
                    organizationLimit: values.availableOrganizations ?? 1,
                    startDate: moment().toISOString(),
                    renewDate: expStr,
                    mySymmioLicense: Number(values.mySymmioLicense),
                    trialLength: values.trialLength,
                    plan: trialPlanObject,
                }

                if (subscriptionId) {
                    orgSub.id = subscriptionId;
                }

                if (values.trialLength && values.trialLength.value === 0) {
                    orgSub.customTrialLength = values.customTrialLength;
                }

                if (subscriptionId) orgSub.id = subscriptionId;

                if (testOrg) {
                    orgSub.testingSub = true;
                }

                await OrganizationSubscriptionService.save(orgSub, user);
            }

            setRefresh(!refresh);
            redirect && history.push(redirect);

            setOrganization(savedOrg);
        }

        // if (!organizationId && values.accountType.value === OrganizationType.Trial) {
        //     const accHolder: User = {
        //         email: values.accountHolderEmail!.toLowerCase(),
        //         status: UserStatus.Active,
        //         isSuperAdmin: false,
        //         organizationId: savedOrg.id,
        //         roles: [UserRoles.ACCOUNT_HOLDER_ID],
        //     };
        //     FunctionService.sendAccountOwnerWelcomeEmail(accHolder);
        // }
        setSubmitting(false);
        onSave && onSave();
        enqueueSnackbar(organizationId ? "Organization edited successfully!" : "Organization created successfully", { variant: "toast", width: "450px" });
    }

    return (
        <>
            <div style={{ margin: '32px 0px 32px 0px', display: 'flex' }}>
                <h1 style={{ display: 'inline', margin: '0px 40px 0px 0px' }}>{organizationId ? 'Edit Organization' : 'Add Organization'}</h1>
                {
                    organization && (
                        <div style={{ display: 'inline', padding: '2px 0px 2.5px 0px' }}>
                            <OrganizationStatusBadge status={getOrganizationStatus()} expired={expired} />
                        </div>
                    )
                }
                {
                    state.user && UserUtil.isDeveloperUser(state.user)
                    && (
                        <div style={{ display: 'inline', width: 'fit-content', background: '#c1d4e0', paddingLeft: '10px', borderRadius: '4px', margin: '1px 0px 1px 60px' }}>
                            <FormControlLabel
                                sx={{ paddingTop: '2px', userSelect: 'none' }}
                                control={<Checkbox onChange={(e) => setTestOrg(e.target.checked)} checked={testOrg} size="small" sx={{ padding: '1px 10px 1px 10px' }} />}
                                label="Testing Org"
                            />
                        </div>
                    )
                }
            </div>
            <Formik
                initialValues={
                    initialForm !== null
                        ? initialForm
                        : {
                            pricePerUser: 3.00,
                            availableOrganizations: 1,
                            startDate: moment(),
                            accountType: accountTypeRadioItems[0],
                            paymentType: PaymentTypeOptions[0],
                        }
                }
                enableReinitialize={loading || newFormTimeout}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={(loading || newFormTimeout) ? null : getValidation()}
                onSubmit={(values: any) => {
                    saveOrganization(values);
                }}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    setErrors
                }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div>

                            <Grid container rowSpacing={3} sx={{ marginBottom: '38px' }}>
                                <Grid item md={12} lg={8}>
                                    <FormikRadioGroup
                                        name="accountType"
                                        items={accountTypeRadioItems}
                                        value={accountType}
                                        onChange={(option: RadioGroupItem) => {
                                            handleAccountTypeChange(option);
                                            setErrors({});
                                        }}
                                    />
                                </Grid>
                                {
                                    organization && (
                                        <>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <p style={{ fontSize: '12px', fontWeight: '700', paddingBottom: '8px' }}>Start Date</p>
                                                <p style={{ fontSize: '16px', fontWeight: '300' }}>{startDate ?? 'n/a'}</p>
                                            </Grid>
                                            <Grid item xs={6} md={3} lg={2}>
                                                <p style={{ fontSize: '12px', fontWeight: '700', paddingBottom: '8px' }}>User Quantity</p>
                                                <span style={{ fontSize: '16px', fontWeight: '700' }}>{userCount}</span>
                                                <span style={{ fontSize: '16px', fontWeight: '300' }}>{` / ${userLimit}`}</span>
                                            </Grid>
                                            <Grid item xs={0} md={6} lg={0} />
                                        </>
                                    )
                                }
                            </Grid>

                            <Grid container columnSpacing={3}>
                                <Grid
                                    item
                                    xs={12} sm={12} md={12} lg={6}
                                    sx={{
                                        borderRight: { xs: 'none', lg: 'solid 2px rgba(18, 22, 34, 0.05)' },
                                        paddingRight: { xs: '0px', lg: '24px' },
                                        paddingBottom: { xs: '32px', sm: '32px', md: '32px', lg: '0px' },
                                    }}
                                >
                                    <DefaultFields
                                        initCustomTrialLength={initCustomTrialLength}
                                        organizationId={organizationId}
                                        accountHolderName={accountHolderName}
                                        loading={loading}
                                        editing={!!organization}
                                        accountType={accountType}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <PlanFields
                                        accountType={accountType}
                                        loading={loading}
                                        editing={!!organization}
                                        startDateCallback={(d: Moment) => setStartDate(d.format('MM/DD/yyyy'))}
                                    />
                                </Grid>
                            </Grid>

                        </div>
                        <Grid container sx={{ marginTop: '36px' }}>
                            {
                                organizationId && (
                                    <Grid item md={12} lg={6}>
                                        <>
                                            <div
                                                style={{
                                                    display: 'inline-block',
                                                    position: 'absolute',
                                                    top: '215px',
                                                    padding: '0 0 0 20px',
                                                }}
                                            >
                                            </div>
                                            <div style={{ margin: '15px 0' }}>
                                                <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    <Switch
                                                        inputProps={{ 'aria-label': 'Active Account' }}
                                                        checked={active}
                                                        onChange={(e: any) => setActive(e.target.checked)}
                                                    />
                                                    <p style={{ display: 'inline-block', fontSize: '12px', fontWeight: '700', marginLeft: '10px' }}>
                                                        Active Account
                                                    </p>
                                                </div>
                                                <div style={{ display: 'block', width: '320px', marginLeft: '68px' }}>
                                                    <p style={{ fontSize: '12px', fontWeight: '300', lineHeight: '18px', color: 'rgba(18, 22, 34, 0.5)' }}>
                                                        Deactivating this organization will eliminate access to the Symmio admin portal and mobile app for account holder and users.
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    </Grid>
                                )
                            }
                            <Grid
                                item
                                md={12} lg={6}
                                sx={{
                                    margin: '40px 0',
                                    columnGap: '20px',
                                    display: 'flex',
                                    justifyContent: {
                                        md: 'left',
                                        lg: organization ? 'right' : 'left'
                                    }
                                }}
                            >
                                {
                                    organization &&
                                    <Button
                                        type="link"
                                        onClick={() => onCancel && onCancel()}
                                        buttonText="Cancel"
                                        buttonType="button"
                                    />
                                }
                                <Button
                                    buttonText={organization ? 'Save' : 'Add Organization'}
                                    disabled={submitting || loading}
                                    buttonType="submit"
                                />
                            </Grid>
                        </Grid>

                    </form>
                )}
            </Formik>
        </>

    );
}