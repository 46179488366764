import { useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { ReactComponent as NotificationsNone } from "../../assets/icons/general-icons/notifications-none.svg";
import { ReactComponent as NotificationsUnread } from "../../assets/icons/general-icons/notifications-unread.svg";
import getFirebase from '../../utilities/firebase';
import { Loader } from '../loader/loader';
import { useHistory } from 'react-router-dom';
import { NotificationItem } from './notification-item';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { Notification } from '../../models/interfaces/notification';
import NotificationService from '../../utilities/services/notification-service';
import { Button } from '../button/button';


// NOTES: Since this component lives on the webpage itself, every time a user navigates to a new page where
// the user menu exists, it would create a new listener. The way listeners work is...
// if I have 100 documents of notifications the listener will think that on it's initial load,
// those 100 documents are flagged as "added"
// old logic would grab ALL notifications (1000+ lets say) on initial load and flag them as "added",
// and for each notification on the initial load, it would call retrieveNotifications() which would then call
// the firebase to get all notifications with receiverId && organizationId
// this meant, depending on the number of notifications present in the DB, it would make a call to grab
// all notifications belonging to the logged in user. As we get to 10,000 notifications, this call would happen
// 10,000, and then it would happen 10,000 every time a user went to a new page
// new pages include the nav bar(users, groups, links, settings, etc)
// NEW LOGIC:
// We can't avoid the listener thinking on initial load that all existing documents are of type "added"
// So we now grab initial notifications outside of the user menu and save it in local storage state.
// Then when the page renders the userMenu, is goes to check state for notifications and uses those.
// If no notifications live in state for some reason, it will call retrieveNotifications() 
// to get all notifications belonging to the logged in user.
// Once initial notifications are loaded, we manage which notifications exist in processedDocIds
// The processedDocIds is a simple string array for quick comparison during initial listener load.
// So that if a user has 200 notifications, it won't count the initial 200 noti load as "added" and will skip
// that snapshot change.
// Then any time a noti is added/modified/removed, we update notifications in state and local storage state
// as well as the processedDocIds
// If issues still continue, next steps will be to remove ALL listening and notification retrieval outside of this
// component and into a component with a longer life cycle to avoid so many listers being loaded and unloaded.
// Another change is to the retrieveNotifications(). We don't need to be calling
// something to retrieve every notification again when a single change has occurred.
// we want to leverage our listener to handle all the work load and adjust
// the notifications 1 at a time.

const { db } = getFirebase();

const COMPONENT_CLASS = "c-notification-menu";

export interface NotificationMenuProps {
  profileImage?: boolean;
}

const NotificationMenu: React.FC<NotificationMenuProps> = (props) => {
  const { state, setState } = useAuthState();
  const [isSwitching,] = useState(false);
  const history = useHistory();
  const [mouseOverButton, setMouseOverButton] = useState(false)
  const [mouseOverMenu, setMouseOverMenu] = useState(false)
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isNotificationsLoaded, setIsNotificationsLoaded] = useState<boolean>(false);
  const processedDocIds = useRef(new Set<string>());


  const timeoutDuration = 500
  let timeoutButton: string | number | NodeJS.Timeout | undefined;
  let timeoutMenu: string | number | NodeJS.Timeout | undefined;

  // checking for initial load of notifications
  useEffect(() => {
    // if state contains the notifications, or if notifications !== state notifications
    if (!isNotificationsLoaded) {
      if (state.notifications && state.notifications.data && notifications !== state.notifications.data) {
        // clean processedIds before assigning
        processedDocIds.current.clear();
        // Track all notifications that have already been loaded
        for (const notification of state.notifications.data) {
          if (notification.id) {
            processedDocIds.current.add(notification.id);
          }
        }
        setNotifications(state.notifications.data);
        setIsNotificationsLoaded(true);
      }
      else {
        retrieveNotifications()
        setIsNotificationsLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, state.notifications]);

  const onMouseEnterButton = () => {
    clearTimeout(timeoutButton)
    setMouseOverButton(true)
  }
  const onMouseLeaveButton = () => {
    timeoutButton = setTimeout(() => setMouseOverButton(false), timeoutDuration)
  }

  const onMouseEnterMenu = () => {
    clearTimeout(timeoutMenu)
    setMouseOverMenu(true)
  }
  const onMouseLeaveMenu = () => {
    timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration)
  }

  const onDelete = async (notificationId: string) => {
    await NotificationService.deleteById(notificationId);
  }

  const onRead = async (notificationId: string) => {
    if (state.notifications) {
      const notificationToRead = state.notifications.data.filter((item: any) => item.id === notificationId)[0];
      const readNotification: Notification = {
        ...notificationToRead,
        read: true,
      }
      await NotificationService.update(readNotification, state.user);
      history.push(notificationToRead && notificationToRead.redirectUrl ? notificationToRead?.redirectUrl : '')
    }
  }

  const markAllAsRead = async () => {
    if (state.user && state.user.id && state.organization.id) {
      await NotificationService.markAllAsRead(state.user.id, state.organization.id, state.user);
    }
  }

  const clearAll = async () => {
    if (state.user && state.user.id && state.organization.id) {
      await NotificationService.clearAll(state.user.id, state.organization.id, state.user);
    }
  }


  const show = (mouseOverMenu || mouseOverButton)

  const retrieveNotifications = async () => {
    if (state.user && state.user.id && state.organization.id && state.authenticated) {
      const notificationsList = await NotificationService.getNotifications(state.user.id, state.organization.id);
      // eslint-disable-next-line array-callback-return
      notificationsList.sort((a: Notification, b: Notification) => {
        if (b.created && a.created) return b.created.toMillis() - a.created.toMillis()
      });
      // clean processedIds before assigning
      processedDocIds.current.clear();
      // Track all notifications that have already been loaded
      for (const notification of notificationsList) {
        if (notification.id) {
          processedDocIds.current.add(notification.id);
        }
      }
      setNotifications(notificationsList);
      setState((state) => ({
        ...state, ...{
          notifications: {
            data: notificationsList,
            count: notificationsList.filter((item: any) => item.read === false).length > 99
              ? '99+'
              : notificationsList?.filter((item: any) => item.read === false).length
          }
        }
      }));
    }
  }

  const getNotificationsCount = () => {
    return state.notifications?.count ?? 0;
  }

  // subscribe to changes in FB, if changes occur, retrieve them.
  useEffect(() => {
    if (state.user && isNotificationsLoaded) {

      // get all notifications that belong to user
      const usersCollection = query(
        collection(db, 'notifications'),
        where('receiverId', '==', state.user.id),
        where('organizationId', '==', state.organization.id),
      );
      return onSnapshot(usersCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added' && change.doc.data() && !processedDocIds.current.has(change.doc.id)) {
            // keeping these variables in the if since on initial load all existing documents will create variables when they won't be used
            const docData = change.doc.data() as Notification;
            const docId = change.doc.id;
            console.log("added!");
            // Add the document ID to the processedDocIds set
            processedDocIds.current.add(docId);
            // Add the new notification to the state and localstorage state
            setNotifications((prevNotifications: any) => {
              // Update localstorage state
              setState((state: any) => ({
                ...state, ...{
                  notifications: {
                    data: [docData, ...prevNotifications],
                    count: [docData, ...prevNotifications].filter((item: any) => item.read === false).length > 99
                      ? '99+'
                      : [docData, ...prevNotifications]?.filter((item: any) => item.read === false).length
                  }
                }
              }));
              return [docData, ...prevNotifications]
            });
          }
          if (change.type === "modified") {
            const docData = change.doc.data() as Notification;
            const docId = change.doc.id;
            console.log("modified doc", change.doc.data())
            // Update the notification in the state and localstorage state
            setNotifications((prevNotifications) => {
              const newNotifications = prevNotifications.map((notification) =>
                notification.id === docId ? { id: docId, ...docData } : notification
              )
              // Update localstorage state
              setState((state: any) => ({
                ...state, ...{
                  notifications: {
                    data: newNotifications,
                    count: newNotifications.filter((item: any) => item.read === false).length > 99
                      ? '99+'
                      : newNotifications?.filter((item: any) => item.read === false).length
                  }
                }
              }));
              return newNotifications
            });
          }
          if (change.type === "removed") {
            const docId = change.doc.id;
            console.log("removed doc", change.doc.data())
            // Remove the document ID from the processedDocIds set
            processedDocIds.current.delete(docId);
            // Remove the notification from the state and localstorage state
            setNotifications((prevNotifications) => {
              const newNotifications = prevNotifications.filter((notification) => notification.id !== docId)
              // Update localstorage state
              setState((state: any) => ({
                ...state, ...{
                  notifications: {
                    data: newNotifications,
                    count: newNotifications.filter((item: any) => item.read === false).length > 99
                      ? '99+'
                      : newNotifications?.filter((item: any) => item.read === false).length
                  }
                }
              }));
              return newNotifications;
            }
            );
          }
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user, isNotificationsLoaded]);

  useEffect(() => {
    // if state contains the notifications, or if notifications !== state notifications
    if (state.notifications && state.notifications.data && notifications !== state.notifications.data) {
      setNotifications(state.notifications.data);
    }
  }, [notifications, state.notifications]);

  return (
    <div className={`${COMPONENT_CLASS}`}>
      <Loader
        isVisible={isSwitching} />
      <Menu as="div">
        {({ open }) => (
          <>
            <div onMouseEnter={onMouseEnterButton}
              onMouseLeave={onMouseLeaveButton}
            >
              <Menu.Button className={`${COMPONENT_CLASS}__button`}>

                <span className="sr-only">Open user menu</span>
                <div className={`${COMPONENT_CLASS}__count hide-on-mobile`}>
                  {getNotificationsCount()}
                </div>
                {getNotificationsCount() > 0 ? (
                  <NotificationsUnread />
                ) : (
                  <NotificationsNone />
                )}

              </Menu.Button>
            </div>
            <Transition
              show={show}
            // enter="transition ease-out duration-100"
            // enterFrom="transform opacity-0 scale-95"
            // enterTo="transform opacity-100 scale-100"
            // leave="transition ease-in duration-75"
            // leaveFrom="transform opacity-100 scale-100"
            // leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className={`${COMPONENT_CLASS}__menu`}
                onMouseEnter={onMouseEnterMenu}
                onMouseLeave={onMouseLeaveMenu}
              >
                <Menu.Item>
                  <div
                    style={{ width: '400px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    className={`${COMPONENT_CLASS}__header`}>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>Notifications</p>
                    <div>
                      <Button
                        type='link'
                        buttonText='Mark all as read'
                        onClick={() => { markAllAsRead() }} />
                      <span style={{ marginLeft: '1rem' }}>
                        <Button
                          type='link'
                          buttonText='Clear all'
                          onClick={() => { clearAll() }} />
                      </span>
                    </div>
                  </div>
                </Menu.Item>
                <div className={(notifications && notifications.length > 4) ? `${COMPONENT_CLASS}__scrollable` : ''}>
                  {notifications && notifications.length > 0 && notifications.map((notification: any) => {
                    return (
                      <Menu.Item>
                        <NotificationItem notification={notification} onDelete={onDelete} onRead={onRead} />
                      </Menu.Item>
                    )
                  })}
                </div>
                {notifications && notifications.length === 0 && (
                  <Menu.Item>
                    <NotificationItem empty={true} />
                  </Menu.Item>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div >
  );
}

export { NotificationMenu };
