/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import InactivityMessage from "./inactivity-modal-message";
import InactivityModalTitle from "./inactivity-modal-title";
import InactivityModalButtons from "./inactivity-modal-buttons";
import 'firebase/auth';
import getFirebase from "../../utilities/firebase";

export interface InactivityModalProps {
    resetActivity: () => void;
    isModalOpen: boolean;
    setIsModalOpen: Function;
}

const COMPONENT_CLASS = 'c-login-modal';
const WARNING_TIMEOUT_KEY = "logoutWarningTimestamp";
const WARNING_PERIOD_MS = 15 * 60 * 1000; // 15 minutes in milliseconds

/**
 * Represents the modal that is shown when a Inactivity error occurs.
 * 
 * @param props The properties
 * @returns {JSX.Element} The component
 */
const InactivityModal: React.FC<InactivityModalProps> = (props) => {
    const {
        resetActivity,
        isModalOpen,
        setIsModalOpen
    } = props;
    const { auth } = getFirebase();

    const [timerActive, setTimerActive] = useState(false);

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === WARNING_TIMEOUT_KEY) {
                const warningTimestamp = localStorage.getItem(WARNING_TIMEOUT_KEY);
                if (warningTimestamp) {
                    const timeRemaining = parseInt(warningTimestamp, 10) - Date.now();
                    if (timeRemaining > 0) {
                        // Show modal and start timer if warning is active
                        setIsModalOpen(true);
                        setTimerActive(true);
                    } else {
                        // Log out if the timer has expired
                        handleLogout();
                    }
                } else {
                    // Close modal if timestamp is cleared
                    setIsModalOpen(false);
                    setTimerActive(false);
                }
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            // Set warning timestamp in localStorage
            const warningTimestamp = Date.now() + WARNING_PERIOD_MS;
            localStorage.setItem(WARNING_TIMEOUT_KEY, warningTimestamp.toString());
            setTimerActive(true);
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (timerActive) {
            const interval = setInterval(() => {
                const warningTimestamp = localStorage.getItem(WARNING_TIMEOUT_KEY);
                if (warningTimestamp && parseInt(warningTimestamp, 10) <= Date.now()) {
                    handleLogout(); // Log out if timer expires
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [timerActive]);

    const handleClose = () => {
        resetActivity();
        localStorage.removeItem(WARNING_TIMEOUT_KEY); // Clear timestamp on close
    };

    const handleLogout = () => {
        auth.signOut();
        setIsModalOpen(false);
        resetActivity();
        localStorage.removeItem(WARNING_TIMEOUT_KEY); // Clear timestamp on logout
    }
    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            className={COMPONENT_CLASS}
        >
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className={`${COMPONENT_CLASS}__backdrop`} />
                <div className={`${COMPONENT_CLASS}__overlay`} >
                    <Dialog.Panel className="rounded bg-white" style={{ width: '978px', borderRadius: '30px' }}>
                        <div className="flex-block justify-center" >
                            <InactivityModalTitle />
                            <InactivityMessage handleLogout={handleLogout} isModalOpen={isModalOpen}
                            />
                            <InactivityModalButtons
                                handleLogout={handleLogout}
                                resetActivity={handleClose}
                                setIsModalOpen={setIsModalOpen}
                            />
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
};

export default InactivityModal;