export enum MovementFocusArea {
        BALANCE = "BALANCE",
        ROTATION = "ROTATION",
        SHOULDER_CLEARING = "SHOULDER_CLEARING",
        SHOULDER_MOBILITY = "SHOULDER_MOBILITY",
        SPINE_CLEARING = "SPINE_CLEARING",
        SQUAT = "SQUAT",
        TOE_TOUCH = "TOE_TOUCH",
        BALANCE_BREATHING = "BALANCE_BREATHING",
        ROTATION_BREATHING = "ROTATION_BREATHING",
        SHOULDER_CLEARING_BREATHING = "SHOULDER_CLEARING_BREATHING",
        SHOULDER_MOBILITY_BREATHING = "SHOULDER_MOBILITY_BREATHING",
        SPINE_CLEARING_BREATHING = "SPINE_CLEARING_BREATHING",
        SQUAT_BREATHING = "SQUAT_BREATHING",
        TOE_TOUCH_BREATHING = "TOE_TOUCH_BREATHING",
        BREATHING = "BREATHING"
};
