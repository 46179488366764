import { GroupIds } from "../models/enumerations/group-ids";
import { QuestionIds } from "../models/enumerations/question-ids";
import { MskScore } from "../models/interfaces/scores/msk-score";
import AssessmentResponseService from "./services/assessment-response-service/assessment-response-service";
import MskScoreServiceAssessments from "./services/msk-score-service-assessments/msk-score-service-assessments";
type ScoreSection = Record<string, any>;
export default class MskScoreUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static async getLatest(
        userId: string
    ): Promise<MskScore | undefined> {
        const mskScores = await MskScoreServiceAssessments.getBy(
            // filter
            [
                {
                    field: "userId",
                    operator: "==",
                    value: userId,
                },
            ],
            // order
            [
                {
                    field: "created",
                    direction: "desc",
                },
            ],
            // limit
            1
        );

        if (mskScores == null || mskScores.length === 0) {
            return undefined;
        }

        return mskScores[0];
    }

    public static getNumberOfPainLocations(mskScore?: MskScore, assessmentResponses?: any) {
        if (!mskScore && !assessmentResponses) {
            return 0;
        }
        let numOfPainCount = 0;
        if (mskScore) {
            numOfPainCount = this.getNumberOfPainLocationsMovementAssessmentFromMsk(mskScore);
        }
        let numOfInjuryCount = 0;
        if (assessmentResponses) {
            numOfInjuryCount = this.getNumberOfPainLocationsInjuryHistoryAssessment(assessmentResponses);
        }

        return numOfPainCount + numOfInjuryCount;
    }

    public static getNumberOfPainLocationsMovementAssessmentFromMsk(mskScore: MskScore) {
        let numOfPainCount = 0;

        if (mskScore.movementScore?.balanceScore?.score === 0) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.hasShoulderClearingPain) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.hasSpineClearingPain) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.rotationScore?.score === 0) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.shoulderMobilityScore?.score === 0) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.squatScore?.score === 0) {
            numOfPainCount++;
        }
        if (mskScore.movementScore?.toeTouchScore?.score === 0) {
            numOfPainCount++;
        }

        return numOfPainCount;
    }

    public static getNumberOfPainLocationsMovementAssessment(assessmentResponses: any) {
        let numOfPainCount = 0;

        if (assessmentResponses) {
            const movementResponse: any = assessmentResponses!.find((assessmentResponse: any) => assessmentResponse[0].groupId === GroupIds.MOVEMENT_HEALTH)
            const movementAssessmentResponse = AssessmentResponseService.getMovementAssessmentResponses(movementResponse);

            if (movementAssessmentResponse.balanceQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.shoulderClearingQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.spineClearingQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.rotationQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.shoulderMobilityQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.squatQPain) {
                numOfPainCount++;
            }
            if (movementAssessmentResponse.toeTouchQPain) {
                numOfPainCount++;
            }
        }

        return numOfPainCount;
    }

    public static getNumberOfPainLocationsInjuryHistoryAssessment(assessmentResponses: any) {
        let numOfInjuryCount = 0;

        const injuryHistory = assessmentResponses.find((response: any) => response[0].groupId === GroupIds.INJURY_HISTORY)
        if (injuryHistory) {
            const response = injuryHistory[0].responses.find((response: any) => response.questionId === QuestionIds.INJURY_HISTORY_PAIN_AREAS);
            if (response) {
                let temp = response.answerResponse.split(",").length;
                numOfInjuryCount += temp;
            }
        }

        return numOfInjuryCount;
    }

    public static checkMskScore = (latestMskData?: MskScore): boolean => {
        if (!latestMskData) return false;

        const { movementScore, lifestyleScore } = latestMskData;

        // Utility function to check if all specified keys exist in an object
        const hasKeys = (obj: ScoreSection | undefined, keys: string[]): boolean =>
            keys.every(key => obj?.hasOwnProperty(key));

        // Check movementScore sections
        const toeTouchValid = hasKeys(movementScore?.toeTouchScore, [
            'frontToeLeft', 'backToeLeft', 'frontToeRight', 'backToeRight', 'painSeverity'
        ]);

        const shoulderMobilityValid = hasKeys(movementScore?.shoulderMobilityScore, [
            'paperPassLeft', 'paperPassRight', 'fingerTipsLeft', 'fingerTipsRight', 'painSeverity'
        ]);

        const rotationValid = hasKeys(movementScore?.rotationScore, [
            'feetTogetherLeft', 'feetTogetherRight', 'rightFootBackLeft', 'rightFootBackRight', 'painSeverity'
        ]);

        const squatValid = hasKeys(movementScore?.squatScore, [
            'squat', 'fingerTips', 'fists', 'painSeverity'
        ]);

        const balanceValid = hasKeys(movementScore?.balanceScore, [
            'oneFootRight', 'oneFootLeft', 'painSeverity'
        ]) &&
        hasKeys(movementScore?.balanceScore?.twoFeetLeft, ['attempts', 'success']) &&
        hasKeys(movementScore?.balanceScore?.twoFeetRight, ['attempts', 'success']) &&
        hasKeys(movementScore?.balanceScore?.threeFeetLeft, ['attempts', 'success']) &&
        hasKeys(movementScore?.balanceScore?.threeFeetRight, ['attempts', 'success']);

        const clearingPainValid = hasKeys(movementScore, [
            'shoulderClearingPainSeverity', 'spineClearingPainSeverity'
        ]);

        // Check lifestyleScore sections
        const bodyCompositionValid = hasKeys(lifestyleScore?.bodyCompositionScore, ['bmi']);
        const breathingValid = hasKeys(lifestyleScore?.breathingScore, [
            'breathHoldTime', 'breathingQuestionnaireScore', 'isSmoking'
        ]);
        const injuryHistoryValid = hasKeys(lifestyleScore?.injuryHistoryScore, [
            'previousInjury', 'currentPain', 'seenPhysician', 'recoveryScore'
        ]);
        const sleepValid = hasKeys(lifestyleScore?.sleepScore, ['psqiScore']);

        return (
            !!movementScore &&
            !!lifestyleScore &&
            toeTouchValid &&
            shoulderMobilityValid &&
            rotationValid &&
            squatValid &&
            balanceValid &&
            clearingPainValid &&
            bodyCompositionValid &&
            breathingValid &&
            injuryHistoryValid &&
            sleepValid
        );
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    // #endregion Private Methods
}
