import FirestoreCondition from "../../models/interfaces/firestore-condition";
import FirestoreOrder from "../../models/interfaces/firestore-order";
import { Log } from "../../models/interfaces/log";
import { User } from "../../models/interfaces/user";
import FirestoreService from "./firestore-service";

const COLLECTION_NAME = "logs";

// -----------------------------------------------------------------------------------------
// #region Service Methods
// -----------------------------------------------------------------------------------------

/**
 * Add a new log to the logs collection
 * @param {Log} log - The log that is being added to the collection
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Log>} A promise to the newly added log
 */
const add = async (log: Log, currentUser: User | null = null) => {
    return FirestoreService.add<Log>(COLLECTION_NAME, {...log, version: "Portal"}, currentUser);
};

/**
 * Delete a log collection by the Id
 * @param {string} id - The Id of the log being deleted
 */
const deleteById = async (id: string) => {
    FirestoreService.deleteById(COLLECTION_NAME, id);
};

/**
 * Find the specific log by the id
 * @param {string} id - The Id of the log that we are retrieving
 * @returns {Promise<Log>} A promise for the Tag we are retrieving
 */
const get = async (id: string) => {
    return FirestoreService.get<Log>(COLLECTION_NAME, id);
};

/**
 * Find the specific user by the id
 * @param {string} id - The Id of the user that we are retrieving
 * @returns {Promise<User>} A promise for the User we are retrieving
 */
const getBy = async (conditions: FirestoreCondition[], order: FirestoreOrder[] = [], limit?: number) => {
    return FirestoreService.getBy<Log>(COLLECTION_NAME, conditions, order, limit);
};


/**
 * Get all of the logs stored in the database
 * @returns {Promise<Tag[]>} A promise for the collection of logs
 */
const getAll = async (order: FirestoreOrder[] = []) => {
    return FirestoreService.getAll<Log>(COLLECTION_NAME, order);
};

/**
 * Save the specified log in the database
 * @param {Log} log - The log that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Log>} A promise for the log that is being updated
 */
const save = async (log: Log, currentUser: User | null = null) => {
    return FirestoreService.save<Log>(COLLECTION_NAME, log, currentUser);
};

/**
 * Update the specified log stored in the database
 * @param {Log} log - The log that is being updated
 * @param {User} currentUser - The user that is logged into the application
 * @returns {Promise<Log>} A promise for the log that is being updated
 */
const update = async (log: Log, currentUser: User | null = null) => {
    return FirestoreService.update<Log>(COLLECTION_NAME, log, currentUser);
};

// #endregion Service Methods


// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const LogService = {
    add,
    deleteById,
    get,
    getBy,
    getAll,
    save,
    update,
};

export default LogService;

// #endregion Exports